<template>
  <div class="container">
    <h2>Tips</h2>

    <div class="contact-information">
      <p class="contact-information__block">
        <span class="contact-information__row">California Department of Fish and Game</span>
        <span class="contact-information__row">1416 Ninth St.</span>
        <span class="contact-information__row">Sacramento, CA 95814</span>
        <span class="contact-information__row">(916) 984-7123</span>
      </p>
      <p class="contact-information__block">
        <span class="contact-information__row">Nevada Division of Wildlife</span>
        <span class="contact-information__row">1100 Valley Rd.</span>
        <span class="contact-information__row">Reno, NV 89512</span>
        <span class="contact-information__row">(775) 688-1500 General Info</span>
        <span class="contact-information__row">(775) 688-1331 Report Incidents to Dispatch</span>
      </p>
      <p class="contact-information__block">
        <span class="contact-information__row">Bear League</span>
        <span class="contact-information__row">24 hour hotline</span>
        <span class="contact-information__row">(530) 525-7297</span>
      </p>
      <p class="contact-information__block">
        <span class="contact-information__row">Lake Tahoe Wildlife Care Center</span>
        <span class="contact-information__row">1485 Cherry Hills Circle</span>
        <span class="contact-information__row">South Lake Tahoe, CA 96150</span>
        <span class="contact-information__row">(530) 577-2273</span>
      </p>
      <p class="contact-information__block">
        <span class="contact-information__row">Lake Tahoe Basin Management Unit Forest Service Office</span>
        <span class="contact-information__row">35 College Drive</span>
        <span class="contact-information__row">South Lake Tahoe, CA 96150</span>
        <span class="contact-information__row">(530) 543-2600</span>
      </p>
      <p class="contact-information__block">
        <span class="contact-information__row">Tahoe Council for Wild Bears</span>
        <span class="contact-information__row">P.O. Box 393</span>
        <span class="contact-information__row">Homewood, CA 96141</span>
      </p>
      <p class="contact-information__block">
        <span class="contact-information__row">Keep Tahoe Blue</span>
        <span class="contact-information__row">2608 Lake Tahoe Boulevard</span>
        <span class="contact-information__row">South Lake Tahoe, CA 96150</span>
        <span class="contact-information__row">(530) 541-5388</span>
      </p>
      <p class="contact-information__block">
        <span class="contact-information__row">Tahoe Daily Tribune</span>
        <span class="contact-information__row">3079 Harrison Avenue</span>
        <span class="contact-information__row">South Lake Tahoe, CA 96150</span>
        <span class="contact-information__row">(530) 541-3880</span>
      </p>
    </div>


    <h3>Forest Service Guidelines for Living in Bear Habitat</h3>
    <div>
      <h4>Bear-proof Your Property</h4>

      <span>Bears are attracted to anything that is edible or that they associate with food. Take precautions by implementing the following ideas:</span>
      <ul>
        <li>Garbage problems can be solved with the purchase and correct use of a bear-proof garbage container. Save money by sharing one with a neighbor! For approved bear-proof containers and where to buy them, visit the Tahoe Council for Wild Bears web site.</li>
        <li>Wait to put trash out until collection day.</li>
        <li>Don't leave trash, groceries or animal feed in your car.</li>
        <li>Keep garbage cans clean and deodorize them with bleach or unscented ammonia.</li>
        <li>Harvest fruit off trees as soon as it is ripe, and promptly collect fruit that falls.</li>
        <li>Do not feed wildlife.</li>
        <li>Keep barbecue grills clean.</li>
        <li>Feed pets inside.</li>
        <li>Securely block access to sites such as crawl spaces under decks and buildings to reduce the potential of hibernation under structures.</li>
        <li>Don't leave any scented products outside, even non-food items such as suntan lotion, insect repellent, soap and/or candles.</li>
        <li>Keep doors and windows secure from intrusions. Scents can lure bears inside.</li>
        <li>When unoccupied, empty cabins of all food and scented products or keep them in a scent free, bear-proof container.</li>
      </ul>

      <h4>A Fed Bear is a Dead Bear!</h4>
      <p>Once habituated to human sources of food, bears will seek them out, creating conflicts with humans. The bear's behavior will not stop voluntarily, and unless the nuisance behavior can be corrected, bears may be killed for various reasons including property damage or due to collisions with vehicles. In order to avoid these deaths, the food source must be removed.</p>

       

      <h4>Prevention is the Key</h4>
      <p>Bears that visit human occupied areas are usually in search of food. You will be less likely to have encounters with bears if the recommended precautions are taken, so that bears do not associate humans with a source of food. Once they do, they become habituated to humans .Relocating habituated bears relocates the problem, it does not solve it. It is illegal in California and strongly discouraged in Nevada to feed bears, and many communities have ordinances penalizing those who feed wild animals.</p>

       

      <h4>Bear Encounters</h4>
      <p>
        <span class="bold-row">In Your Yard:  Do not run.</span>
        Be aggressive and assert your dominance by standing tall and making noise that will scare the bear away. Banging pots and pans together and shouting loudly works well.

        In the Woods:  This is the bear's territory, respect that and do not run. Make eye contact cut don't stare, pick up small children, make yourself appear as large as possible, stay calm and quiet, back away and enjoy the experience from a safe distance.

        Anywhere:  If the bear attempts to get away, do not block the bear's escape route! Bears will often climb a tree if frightened and usually won't come down it humans or dogs are present.

        Never get between a mom and her cubs.

        Slowly walk away and make a loud noise.

        Though attacks are very rare, if you are attacked, fight back aggressively!
      </p>  
    </div>

  </div>
</template>

<script>
  export default {
    name: 'tips-page',
    data () {
      return {
      msg: 'Tips'
    }
  }
}
</script>

<style lang="scss">
.contact-information {
  display: flex;
  flex-wrap: wrap;

  &__block {
    margin: 8px 0;
    flex: 1 1 50%;
  }

  &__row {
    display: block;
  }
}

.bold-row {
  display: block;
  font-weight: 400;
  text-transform: uppercase;
}
</style>