<template>
   <div class="container">
    <h2>About us:</h2>
    <p>
      Al  Tahoe Forest Homes Association located in South Lake Tahoe between Tahoe Blvd (HWY50) & Freel Peak Ave and Tulare Ave & Los Angles Ave. This historic neighborhood is a charming collection of summer vacation homes, rustic cabins and year round residences.
    </p>
    <div class="bottom-section">
      <div>
        <h3>Contact us:</h3>
        <p>
          <span class="contact-us-block">If you wish to contact us, please write to:</span>
          <span class="contact-us-block">Al Tahoe Forest Homes Association</span>
          <span class="contact-us-block">P.O. Box 13092</span>
          <span class="contact-us-block">South Lake Tahoe , CA , 96151 US</span>

          <span class="contact-us-block contact-us-block__bottom">Or e-mail us at: <a href="emailto:altahoeforesthomes@gmail.com">altahoeforesthomes@gmail.com</a></span>

          <span class="social-media-block"><a class="facebook" href="https://www.facebook.com/ATFHA.SLT/" target="_blank"></a> <a class="instagram" href="https://www.instagram.com/al_tahoe_homes/?hl=en" target="_blank"></a></span>
        </p>
      </div>
<!--       <a href="https://py.pl/6oE9BeQMdBD" target="_blank">
        <h3>Dues:</h3>
        <img class="paypal-image" src="@/assets/paypal_dues.png" />
      </a>
      <a href="https://py.pl/31MtrBpqmez" target="_blank">
        <h3>BBQ for 2:</h3>
        <img class="paypal-image" src="@/assets/paypal_general.png" />
      </a>
      <a href="https://py.pl/80c3IlSEMZ1" target="_blank">
        <h3>BBQ for 3:</h3>
        <img class="paypal-image" src="@/assets/paypal_general.png" />
      </a> -->
    </div>
   </div>
</template>

<script>
  export default {
    name: 'home-page',
    data () {
      return {
      msg: 'Home'
    }
  }
}
</script>

<style lang="scss">
.contact-us-block {
  display: block;

  &__bottom {
    margin-top: 8px;
  }
}

.social-media-block {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.facebook {
  height: 36px;
  width: 36px;
  background-image: url('~@/assets/facebook.png');
  background-size: cover;
}
.instagram {
  height: 50px;
  width: 52px;
  background-image: url('~@/assets/instagram.png');
  background-size: cover;
}

.bottom-section {
  display: flex;
  gap: 8px;

  a {
    color: black;
    text-decoration: none;
  }
}

.paypal-image {
  max-width: 250px;
  height: auto;
  object-fit: contain;
  margin: 0 8px;
}
</style>
