<template>
  <div class="container">
    <h2>Lot Numbers</h2>
    <div class="lots">
      <table>
        <thead>
          <tr>
            <th>Lot Number</th>
            <th>Address</th>
          </tr>
        </thead>
        <tr v-for="item in lotsGroupOne" :key="item.number">
          <td>{{ item.number }}</td>
          <td>{{ item.address }}</td>
        </tr>
      </table>

      <table>
        <thead>
          <tr>
            <th>Lot Number</th>
            <th>Address</th>
          </tr>
        </thead>
        <tr v-for="item in lotsGroupTwo" :key="item.number">
          <td>{{ item.number }}</td>
          <td>{{ item.address }}</td>
        </tr>
      </table>

      <table>
        <thead>
          <tr>
            <th>Lot Number</th>
            <th>Address</th>
          </tr>
        </thead>
        <tr v-for="item in lotsGroupThree" :key="item.number">
          <td>{{ item.number }}</td>
          <td>{{ item.address }}</td>
        </tr>
      </table>

      <table>
        <thead>
          <tr>
            <th>Lot Number</th>
            <th>Address</th>
          </tr>
        </thead>
        <tr v-for="item in lotsGroupFour" :key="item.number">
          <td>{{ item.number }}</td>
          <td>{{ item.address }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'lots-page',
    data () {
      function sortAddresses(a, b) {
        const splitA = a.address.split(' ');
        const splitB = b.address.split(' ');

        if (splitA[1] > splitB[1]) {
          return 1;
        } else if (splitA[1] < splitB[1]) {
          return -1
        } else {
          return splitA[0] > splitB[0] ? 1 : -1;
        }
      }
      return {
        lotsGroupOne: [
          { number: 78, address: "973 Bigler" },
          { number: 80, address: "969 Bigler" },
          { number: 82, address: "961 Bigler" },
          { number: 84, address: "953 Bigler" },
          { number: 86, address: "947 Bigler" },
          { number: 88, address: "941 Bigler" },
          { number: 89, address: "980 Bigler" },
          { number: 91, address: "974 Bigler" },
          { number: 93, address: "968 Bigler" },
          { number: 95, address: "960 Bigler" },
          { number: 97, address: "952 Bigler" },
          { number: 99, address: "944 Bigler" },
          { number: 60, address: "989 Cave Rock" },
          { number: 62, address: "983 Cave Rock" },
          { number: 64, address: "973 Cave Rock" },
          { number: 66, address: "969 Cave Rock" },
          { number: 68, address: "961 Cave Rock" },
          { number: 70, address: "953 Cave Rock" },
          { number: 72, address: "945 Cave Rock" },
          { number: 74, address: "939 Cave Rock" },
          { number: 77, address: "974 Cave Rock" },
          { number: 79, address: "966 Cave Rock" },
          { number: 81, address: "960 Cave Rock" },
          { number: 83, address: "952 Cave Rock" },
          { number: 85, address: "946 Cave Rock" },
          { number: 87, address: "940 Cave Rock" },
        ].sort(sortAddresses),
        lotsGroupTwo: [
          { number: 25, address: "3020 Freel Peak" },
          { number: 26, address: "3010 Freel Peak" },
          { number: 58, address: "2960 Freel Peak" },
          { number: 101, address: "2906 Freel Peak" },
          { number: 102, address: "2891 Freel Peak" },
          { number: 103, address: "2901 Freel Peak" },
          { number: 104, address: "2907 Freel Peak" },
          { number: 105, address: "2915 Freel Peak" },
          { number: 106, address: "2921 Freel Peak" },
          { number: 107, address: "2927 Freel Peak" },
          { number: 108, address: "2935 Freel Peak" },
          { number: 109, address: "2941 Freel Peak" },
          { number: 110, address: "2949 Freel Peak" },
          { number: 111, address: "2955 Freel Peak" },
          { number: 112, address: "2961 Freel Peak" },
          { number: 113, address: "2971 Freel Peak" },
          { number: 114, address: "2981 Freel Peak" },
          { number: 115, address: "2987 Freel Peak" },
          { number: 116, address: "2995 Freel Peak" },
          { number: 117, address: "3001 Freel Peak" },
          { number: 118, address: "3007 Freel Peak" },
          { number: 119, address: "3015 Freel Peak" },
          { number: 28, address: "991 Gold Tip" },
          { number: 30, address: "981 Gold Tip" },
          { number: 32, address: "975 Gold Tip" },
          { number: 34, address: "969 Gold Tip" },
          { number: 36, address: "961 Gold Tip" },
          { number: 38, address: "953 Gold Tip" },
          { number: 40, address: "947 Gold Tip " },
          { number: 42, address: "941 Gold Tip" },
          { number: 43, address: "988 Gold Tip" },
          { number: 45, address: "980 Gold Tip" },
          { number: 47, address: "974 Gold Tip" },
          { number: 49, address: "966 Gold Tip" },
          { number: 51, address: "960 Gold Tip" },
          { number: 53, address: "952 Gold Tip" },
          { number: 55, address: "946 Gold Tip" },
          { number: 57, address: "940 Gold Tip" },
        ].sort(sortAddresses),
        lotsGroupThree: [
          { number: 2, address: "973 Los Angeles" },
          { number: 3, address: "963 Los Angeles" },
          { number: 4, address: "955 Los Angeles" },
          { number: 5, address: "947 Los Angeles" },
          { number: 6, address: "943 Los Angeles" },
          { number: 7, address: "935 Los Angeles" },
          { number: 8, address: "929 Los Angeles" },
          { number: 9, address: "919 Los Angeles" },
          { number: 10, address: "913 Los Angeles" },
          { number: 11, address: "974 Los Angeles" },
          { number: 13, address: "970 Los Angeles" },
          { number: 15, address: "962 Los Angeles" },
          { number: 17, address: "954 Los Angeles" },
          { number: 19, address: "946 Los Angeles" },
          { number: 21, address: "940 Los Angeles" },
          { number: 23, address: "934 Los Angeles" },
          { number: 120, address: "912 Los Angeles" },
          { number: 59, address: "2951 Lake Tahoe" },
          { number: "75 & 76", address: "2941 Lake Tahoe" },
          { number: 1, address: "3043 Harrison Ave." },
        ].sort(sortAddresses),
        lotsGroupFour: [
          { number: 12, address: "989 Trout Creek" },
          { number: 14, address: "981 Trout Creek" },
          { number: 16, address: "973 Trout Creek" },
          { number: 18, address: "965 Trout Creek" },
          { number: 20, address: "961 Trout Creek" },
          { number: 22, address: "953 Trout Creek" },
          { number: 24, address: "945 Trout Creek" },
          { number: 27, address: "988 Trout Creek" },
          { number: 29, address: "980 Trout Creek" },
          { number: 31, address: "972 Trout Creek" },
          { number: 33, address: "968 Trout Creek" },
          { number: 35, address: "960 Trout Creek" },
          { number: 37, address: "952 Trout Creek" },
          { number: 39, address: "944 Trout Creek" },
          { number: 41, address: "940 Trout Creek" },
          { number: 44, address: "989 Star Lake" },
          { number: 46, address: "981 Star Lake" },
          { number: 48, address: "975 Star Lake" },
          { number: 50, address: "969 Star Lake" },
          { number: 52, address: "961 Star Lake" },
          { number: 54, address: "953 Star Lake" },
          { number: 56, address: "947 Star Lake" },
          { number: 61, address: "980 Star Lake" },
          { number: 63, address: "974 Star Lake" },
          { number: 65, address: "966 Star Lake" },
          { number: 67, address: "960 Star Lake" },
          { number: 69, address: "952 Star Lake" },
          { number: 71, address: "946 Star Lake" },
          { number: 73, address: "940 Star Lake" },
          { number: 90, address: "981 Tulare" },
          { number: 92, address: "969 Tulare" },
          { number: 94, address: "961 Tulare" },
          { number: 96, address: "955 Tulare" },
          { number: 98, address: "947 Tulare" },
          { number: 100, address: "941 Tulare" },
        ].sort(sortAddresses)
    }
  }
}
</script>

<style lang="scss">
.lots {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    justify-content: space-evenly;
  }

  th {
    border-bottom: 1px solid black;
  }

  table {
    height: 25px;
  }
}
</style>
