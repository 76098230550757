<template>
  <div class="container">
    <h2>Documents</h2>
    <div class="by-laws">
      <ul>
        <li class="by-laws__item">
          <a href="/documents/ATFHA_Articles_of_Incorporation.pdf" target="_blank">ATFHA Articles of Incorporation</a>
        </li>
        <li class="by-laws__item">
          <a href="/documents/ATFH_bylaws10-9-2010.pdf" target="_blank">ATFHA Bylaws (10/9/2010)</a>
        </li>
        <li class="by-laws__item">
          <a href="/documents/ATFH-CC_R_S_Third_Amended_Declaration_-_Final_091318_-_RECORDED.pdf" target="_blank">ATFHA CC&R 3rd Amended (9/13/2018)</a>
        </li>
        <li class="by-laws__item">
          <a href="/documents/ARC_Checklist_2020.pdf" target="_blank">ATFHA Architectural Review Process (2020)</a>
        </li>
      </ul>

      <img class="by-laws__image" src="@/assets/altahoetract.jpg" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'by-laws-page',
    data () {
      return {
      msg: 'Home'
    }
  }
}
</script>

<style lang="scss">
.by-laws {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    justify-content: space-evenly;
  }
  

  &__item {
    margin: 2px 0;
  }
  &__image {
    max-width: 100%;
  }
}
</style>
