<template>
  <div class="container">
    <h2>Volunteer Opportunities for our Residence</h2>

    <h3>Neighborhood watch</h3>
    <p>We currently are seeking residents to join our community watch program. Our neighborhood has had several bear sightings and break ins. Trash left in garbage cans is often an easy meal for the bears.</p>

    <h3>Annual BBQ Help</h3>
    <p>Once a year the Saturday of Labor day weekend, we hold our homeowners meeting and BBQ. We need help with set up, cooking and clean up. Please contact ATFH if you are interested in helping out with our annual BBQ</p>

    <h3>Board Members</h3>
    <p>For Al Tahoe Forest Home residence this is your community and your association.  The current board encourages member to get involved. We welcome all members in good standing to join your Homeowners board.</p>

    <p>
      <span class="contact-us-block">If you are interested in any of the above opportunities, please contact ATFH</span>
      <span class="contact-us-block">If you wish to contact us, please write to:</span>
      <span class="contact-us-block">Al Tahoe Forest Homes Association</span>
      <span class="contact-us-block">P.O. Box 13092</span>
      <span class="contact-us-block">South Lake Tahoe, CA, 96151 US</span>

      <span class="contact-us-block contact-us-block__bottom">Or e-mail us at: <a href="emailto:altahoeforesthomes@gmail.com">altahoeforesthomes@gmail.com</a></span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'volunteer-page',
  data () {
    return {
      msg: 'Home'
    };
  }
}
</script>

<style lang="scss">
.contact-us-block {
  display: block;

  &__bottom {
    margin-top: 8px;
  }
}
</style>
