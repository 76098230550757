<template>
  <div id="app">
    <div class="app-title-container">
      <h1 class="app-title-container__title">Al Tahoe Forest Homes Association</h1>
      <div class="app-title-container__nav-bar">
        <router-link class="app-title-container__nav-bar-item" to="/">Home</router-link>
        <router-link class="app-title-container__nav-bar-item" to="/tips">Tips</router-link>
        <router-link class="app-title-container__nav-bar-item" to="/photos">Photos</router-link>
        <router-link class="app-title-container__nav-bar-item" to="/volunteer">Volunteer</router-link>
        <router-link class="app-title-container__nav-bar-item" to="/history">History</router-link>
        <router-link class="app-title-container__nav-bar-item" to="/documents">Documents</router-link>
        <router-link class="app-title-container__nav-bar-item" to="/lots">Lots</router-link>
      </div>
    </div>
    <!-- route outlet -->
    <!-- component matched by the route will render here -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    //HelloWorld
  },
  computed: {
    // username() {
    //   // We will see what `params` is shortly
    //   return this.$route.params.username
    // }
  },
  methods: {
    // goBack() {
    //   window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    // }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  color: #333333;
  font-weight: 300;
}
h1, h2, h3, h4, h5 {
  font-weight: 700;
}
a {
  color: #4680FE;

  &:hover {
    opacity: 0.8;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-title-container {
  display: flex;
  justify-content: flex-end;
  background-image: url('~@/assets/jeffrey_pine.jpg');
  background-size: cover;
  background-position: bottom;
  height: 300px;
  flex-direction: column;
  align-items: center;

  &__title {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    font-size: 30px;
    color: white;
    margin: 0;
    padding: 16px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.25);
    font-weight: 700;

    @media (max-width: 550px) {
      padding: 8px 1px;
    }
  }

  &__nav-bar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: 0;
    padding: 8px 16px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &__nav-bar-item {
    color: white;
    padding: 4px 12px;
    margin: 0 4px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, 0.75);
    border-radius: 4px;

    &:hover {
      opacity: 0.6;
    }

    @media (max-width: 550px) {
      margin: 4px;
    }
  }
}

.container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: 0 8px 24px;
  text-align: left;
}
</style>
