import Vue from 'vue'
import Router from 'vue-router'

import BBQ from '@/components/BBQ'
import ByLaws from '@/components/ByLaws'
import History from '@/components/History'
import Home from '@/components/Home'
import Tips from '@/components/Tips'
import Volunteer from '@/components/Volunteer'
import Lots from '@/components/Lots'

Vue.use(Router)

export default new Router({
  routes: [
    { path: '/', name: 'Home', component: Home },
    { path: '/photos', name: 'Photos', component: BBQ },
    { path: '/documents', name: 'Documents', component: ByLaws },
    { path: '/history', name: 'History', component: History },
    { path: '/tips', name: 'Tips', component: Tips },
    { path: '/volunteer', name: 'Volunteer', component: Volunteer },
    { path: '/lots', name: 'Lots', component: Lots }
  ]
})