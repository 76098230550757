<template>
  <div class="container">
    <h2>Photos</h2>

    <p>
      <span class="bbq-row">There is an annual homeowners meeting & BBQ during Labor Day weekend, on the Saturday.</span>
      <span class="bbq-row">Board Meeting: 10:00</span>
      <span class="bbq-row">Annual Meeting: 11:00</span>
      <span class="bbq-row">BBQ: 4:30</span>
      <span class="bbq-row top-margin">We are always looking for help and support on the annual BBQ. Additionally, we are always looking for photos so if you have any please send them to:</span>
      <span class="bbq-row top-margin"><a href="emailto:altahoeforesthomes@gmail.com">altahoeforesthomes@gmail.com</a></span>
    </p>

    <img class="bbq-image" src="@/assets/bbq/2016-BBQ_Photos.jpg" />

    <img class="bbq-image" src="@/assets/bbq/2014-BBQ_Photos.jpg" />

    <img class="bbq-image" src="@/assets/bbq/2013-BBQ_Photos.jpg" />

    <img class="bbq-image" src="@/assets/bbq/2012-BBQ_Photos.jpg" />

    <img class="bbq-image" src="@/assets/bbq/2011-BBQ_Photos.jpg" />

  </div>
</template>

<script>
  export default {
    name: 'bbq-page',
    data () {
      return {
      msg: 'Home'
    }
  }
}
</script>

<style lang="scss">
.bbq-row {
  display: block;
}
.top-margin {
  margin-top: 8px;
}

.bbq-image {
  max-width: 100%;
}
</style>
