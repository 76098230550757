<template>
  <div class="container">
    <h2>History</h2>

    <p>
      In 1929 the U.S. Forest Service permitted 116 lots in Al Tahoe subdivision No.2. Many of the cabins found in the ATFHA tract were built in the 1930's and 40's. Al Tahoe Forest Homes Association was established in August of 1960 by tenants of the original Al Tahoe Summer Home Tract.
    </p>
    <p>
      The transfer of ownership of the property from the U.S. Forest Service to local ownership was possible due to the Camp Richardson land exchange. This complex transaction took approximately five years to complete and was made possible by ATFHA Board of Directors & members, Cedar Flat lot owners, Mrs. Richardson, Mr and Mrs. Knisley, and Forest Supervisor Douglas Leisz. In 1974 the Harrison Avenue Park Way was established. In the 1960-65 land swap, the ATFHA acquired the 40'wide strip of land from highway 50.  Since the property was not build-able, the members proposed a landscaped bike path dedicated and maintained by the City of South Lake Tahoe.
    </p>

    <div class="history-contact-container">
      <p>
        <span class="contact-us-block">To submit ATFHA content, please write to:</span>
        <span class="contact-us-block">Al Tahoe Forest Homes Association</span>
        <span class="contact-us-block">P.O. Box 13092</span>
        <span class="contact-us-block">South Lake Tahoe, CA, 96151 US</span>

        <span class="contact-us-block contact-us-block__bottom">Or e-mail us at: <a href="emailto:altahoeforesthomes@gmail.com">altahoeforesthomes@gmail.com</a></span>
      </p>
      <div class="history-contact-container__image-container">
        <img class="history-image" src="@/assets/268_Harrison-1.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'history-page',
  data () {
    return {
      msg: 'Home'
    };
  }
}
</script>

<style lang="scss">
.history-contact-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  justify-content: space-between;

  &__image-container {
    display: flex;

    @media (min-width: 745px) {
      flex: 1 1 auto;
      justify-content: center;
    }
  }
}
.contact-us-block {
  display: block;

  &__bottom {
    margin-top: 8px;
  }
}
.history-image {
  max-width: 100%;
}
</style>
